// App.js (or App.jsx - both work)
import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import ContactUs from "./pages/ContactUs";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // Scroll to top on navigation
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  // Update meta tags based on route
  useEffect(() => {
    let title = "SuperTeam SA Validator";
    let metaDescription = "Africa's second Solana validator";

    switch (pathname) {
      case "/":
        title = "SuperTeam SA Validator - Home";
        metaDescription = "Africa's second Solana validator. Stake now and help fund local Solana initiatives in South Africa.";
        break;
      case "/contact-us":
        title = "Contact SuperTeam SA Validator";
        metaDescription = "Get in touch with SuperTeam SA Validator team. We're here to help with all your staking needs.";
        break;
      default:
        break;
    }

    document.title = title;
    
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = metaDescription;
    }
  }, [pathname]);

  return (
    <div className="w-full min-h-screen overflow-x-hidden bg-background-white">
      <div className="flex flex-col min-h-screen relative">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;
