import PropTypes from "prop-types";

const SectionAbout = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-background-white flex flex-row items-start justify-start pt-[71px] pb-[29px] pl-[138px] pr-[50px] box-border gap-[116px] max-w-full text-left text-29xl text-black font-body-normal-bold 
      mq1225:flex-wrap 
      mq750:flex-col mq750:px-6 mq750:pt-[46px] mq750:pb-5 mq750:gap-[29px] ${className}`}
    >
      {/* Image Container */}
      <div className="h-[500px] relative min-w-[479px] max-w-full mq750:min-w-0 mq750:w-full">
        <div className="absolute top-[0px] left-[0px] bg-mediumblue border-orange-100 border-[10px] border-solid box-border w-[479px] h-[284px] mq750:w-full" />
        <img
          className="absolute top-[23px] left-[25px] w-[431px] h-[477px] object-cover z-[1] mq750:w-[calc(100%-50px)]"
          loading="lazy"
          alt=""
          src="/0x1a7904-1@2x.png"
        />
      </div>

      {/* Text Container */}
      <div className="flex-1 flex flex-col items-start justify-start gap-3.5 pt-12 mq750:w-full mq750:pt-0">
        <h1 className="m-0 text-inherit leading-[52.8px] font-bold">
          About us
        </h1>
        <div className="w-[122px] h-px bg-gainsboro-200" />
        <div className="w-full max-w-[554px]">
          <b className="text-xl leading-[28px] text-black-eerie-black block">
            <p className="mb-7">
              Superteam South Africa, founded in 2024 by a group of passionate
              degens and developers, launched Africa's second Solana validator.
              Our mission is to strengthen the Solana network while funding
              impactful Superteam SA initiatives.
            </p>
            <p className="m-0">
              Follow us on social media to stay updated on our work and how
              staking rewards are being used!
            </p>
          </b>
        </div>
      </div>
    </section>
  );
};

SectionAbout.propTypes = {
  className: PropTypes.string,
};

export default SectionAbout;