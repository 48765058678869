import PropTypes from "prop-types";

const HeroHomeInfo = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch bg-gray-300 flex flex-row items-start justify-between pt-[53px] pb-[52px] pl-[161px] pr-10 box-border relative max-w-full gap-[100px] text-left text-29xl text-gray-100 font-body-normal-bold min-h-screen
      mq750:px-6 mq750:pt-24 mq750:flex-col mq750:items-start ${className}`}
    >
      {/* Purple Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900/30 to-transparent pointer-events-none" />

      {/* Content Section */}
      <div className="w-[557px] flex flex-col items-start justify-start pt-[114px] px-0 pb-0 box-border max-w-full z-10
        mq750:w-full mq750:pt-16">
        <div className="self-stretch flex flex-col items-start justify-start gap-10 max-w-full 
          mq750:gap-8">
          {/* Title */}
          <div className="self-stretch">
  <h1 className="m-0 text-inherit leading-[64px] font-bold font-inherit z-[2] 
    mq750:text-[64px] mq750:leading-[72px] 
    mq450:text-[48px] mq450:leading-[56px]">
    <span className="block mb-2">Superteam SA</span>
    <div className="relative inline-flex items-center">
      <img
        className="absolute top-1/2 -translate-y-1/2 left-0 w-[220px] h-[68px] z-[1] 
          mq750:w-[230px] mq750:h-[58px]"
        loading="lazy"
        alt=""
        src="/rectangle-6.svg"
      />
      <span className="relative z-[2] text-gray-400 px-2">Validator</span>
              </div>
            </h1>
          </div>

          {/* Description */}
          <div className="max-w-[437px] text-xl leading-[30px] text-neutral-grey-2 z-[1] 
            mq750:text-2xl mq750:leading-[36px]">
            Africa's second Solana validator. Stake now and help fund local Solana initiatives in South Africa.
          </div>

          {/* Button */}
          <a 
            href="https://stakewiz.com/validator/stsaYQJUhKZDHSqndGtgo6jgbhVaHBSHhtfVWxCwrhD"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer [border:none] py-5 px-12 bg-mediumblue rounded-lg flex items-center justify-center z-[1] hover:bg-blueviolet transition-colors duration-300
              mq750:w-full max-w-[300px] no-underline"
          >
            <b className="text-xl leading-none font-body-normal-bold text-background-white">
              Stake Now
            </b>
          </a>
        </div>
      </div>

      {/* Desktop Decorative Elements */}
      <div className="relative flex-1 h-full ml-10 mq750:hidden">
        {/* Background Circle */}
        <img
          className="w-[456px] relative max-h-full max-w-full z-[1]"
          loading="lazy"
          alt=""
          src="/gelembung.svg"
        />

        {/* Orbital Elements */}
        <div className="absolute top-[40%] right-[40%] w-[300px] h-[300px] animate-orbit-slow z-[2]">
          <div className="w-16 h-16 bg-orange-100 rounded-full opacity-60 [filter:blur(0px)]" />
        </div>
        <div className="absolute top-[45%] right-[45%] w-[400px] h-[400px] animate-orbit-medium z-[2]">
          <div className="w-12 h-12 bg-turquoise rounded-full opacity-60 [filter:blur(0px)]" />
        </div>
        <div className="absolute top-[42%] right-[42%] w-[350px] h-[350px] animate-orbit-fast z-[2]">
          <img
            className="w-[84px] h-[84px] rounded-188xl object-cover"
            src="/6e06d9b120d5a2f3fdf002ea56e6f7c0-1@2x.png"
            alt=""
          />
        </div>
      </div>

      {/* Mobile Decorative Elements */}
      <div className="hidden mq750:block">
        <img
          className="fixed bottom-8 right-8 w-[60px] h-[60px] rounded-188xl object-cover animate-float z-20"
          src="/6e06d9b120d5a2f3fdf002ea56e6f7c0-1@2x.png"
          alt=""
        />
        
        {/* Mobile Purple Gradient */}
        <div className="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-t from-purple-900/50 to-transparent" />
      </div>
    </div>
  );
};

HeroHomeInfo.propTypes = {
  className: PropTypes.string,
};

export default HeroHomeInfo;