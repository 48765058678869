import PropTypes from "prop-types";

const HeroContactPage = ({ className = "" }) => {
  return (
    <div className={`self-stretch flex flex-row items-start justify-start relative max-w-full text-left text-base text-background-white font-body-normal-bold ${className}`}>
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900/30 to-transparent pointer-events-none z-[1]" />

      <div className="flex-1 bg-gray-300 flex flex-row items-end justify-start flex-wrap content-end pt-[53px] pb-[52px] pl-[134px] pr-10 box-border gap-3.5 max-w-full mq750:pl-[67px] mq750:pt-[34px] mq750:pb-[34px] mq750:box-border mq1050:pl-5 mq1050:box-border">
        <div className="flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[120px] box-border min-w-[454px] max-w-full z-[2] mq750:pb-[78px] mq750:box-border mq750:min-w-full">
          <div className="flex flex-col items-start justify-start gap-[25px] max-w-full">
            <div className="bg-orange-100 flex flex-row items-start justify-start pt-0 pb-[7px] pl-0 pr-[7px] z-[1] text-29xl">
              <h1 className="m-0 relative text-inherit leading-[52.8px] font-bold font-[inherit] z-[2]">
                Get in touch
              </h1>
            </div>
            
            <form className="self-stretch flex flex-col items-start justify-start gap-6">
              <div className="self-stretch flex flex-col sm:flex-row items-start justify-start gap-4">
                <div className="w-full sm:w-1/2 flex flex-col items-start justify-start gap-2">
                  <label className="relative leading-[150%] z-[1]">First Name</label>
                  <input
                    className="w-full [border:none] [outline:none] bg-whitesmoke h-14 rounded-lg flex flex-row items-start justify-start p-4 box-border font-body-normal-bold text-base text-silver z-[1]"
                    placeholder="Enter your first name"
                    type="text"
                  />
                </div>
                <div className="w-full sm:w-1/2 flex flex-col items-start justify-start gap-2">
                  <label className="relative leading-[150%] z-[1]">Last Name</label>
                  <input
                    className="w-full [border:none] [outline:none] bg-whitesmoke h-14 rounded-lg flex flex-row items-start justify-start p-4 box-border font-body-normal-bold text-base text-silver z-[1]"
                    placeholder="Enter your last name"
                    type="text"
                  />
                </div>
              </div>

              <div className="self-stretch flex flex-col items-start justify-start gap-2">
                <label className="relative leading-[150%] z-[1]">Your Email</label>
                <input
                  className="w-full [border:none] [outline:none] bg-whitesmoke self-stretch h-14 rounded-lg flex flex-row items-start justify-start p-4 box-border font-body-normal-bold text-base text-silver min-w-[250px] z-[1]"
                  placeholder="Enter your email"
                  type="email"
                />
              </div>

              <div className="self-stretch flex flex-col items-start justify-start gap-2">
                <label className="relative leading-[150%] z-[1]">Message</label>
                <textarea
                  className="[border:none] bg-whitesmoke h-[120px] w-auto [outline:none] self-stretch rounded-lg flex flex-row items-start justify-start p-4 box-border font-body-normal-bold text-base text-silver z-[4]"
                  placeholder="Enter your message"
                  rows={6}
                />
              </div>

              <button className="cursor-pointer [border:none] py-4 px-12 bg-orange-200 rounded-lg flex flex-row items-start justify-center box-border z-[4] hover:bg-chocolate w-[135px]">
                <b className="text-sm leading-[150%] font-body-normal-bold text-gray-300">
                  SEND
                </b>
              </button>
            </form>
          </div>
        </div>

        <div className="h-[302px] flex flex-col items-start justify-start">
          <img
            className="w-[84px] h-[84px] relative rounded-188xl object-cover z-[1]"
            loading="lazy"
            alt="Profile"
            src="/6e06d9b120d5a2f3fdf002ea56e6f7c0-1@2x.png"
          />
        </div>
        <img
          className="w-[456px] relative max-h-full max-w-full z-[1]"
          loading="lazy"
          alt="Decorative bubble"
          src="/gelembung.svg"
        />

        <div className="hidden mq750:block absolute inset-x-0 bottom-0 h-64 bg-gradient-to-t from-purple-900/50 to-transparent z-[1]" />
      </div>
    </div>
  );
};

export default HeroContactPage;
