// components/Navbar.jsx
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";

const Navbar = ({ isAbsolute = false, className = "" }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { title: 'Home', path: '/', isExternal: false },
    { title: 'Contact Us', path: '/contact-us', isExternal: false },
    { 
      title: 'Stake Now', 
      path: 'https://stakewiz.com/validator/stsaYQJUhKZDHSqndGtgo6jgbhVaHBSHhtfVWxCwrhD',
      isExternal: true 
    },
  ];

  const renderNavLink = (item) => {
    if (item.isExternal) {
      return (
        <a
          key={item.path}
          href={item.path}
          target="_blank"
          rel="noopener noreferrer"
          className="text-base font-bold leading-[150%] no-underline text-background-white transition-colors hover:text-gray-300"
          onClick={() => setIsMenuOpen(false)}
        >
          {item.title}
        </a>
      );
    }

    return (
      <Link
        key={item.path}
        to={item.path}
        className={`text-base font-bold leading-[150%] no-underline text-background-white transition-colors hover:text-gray-300
          ${location.pathname === item.path ? 'border-b-2 border-background-white' : ''}`}
        onClick={() => setIsMenuOpen(false)}
      >
        {item.title}
      </Link>
    );
  };

  return (
    <header
      className={`bg-mediumblue w-full flex flex-row items-center justify-between py-4 px-6 box-border text-left text-xl text-background-white font-body-normal-bold relative
      ${isAbsolute ? 'absolute top-0 right-0 left-0 z-[5]' : 'sticky top-0 z-[99]'}
      ${className}`}
    >
      {/* Logo Section */}
      <div className="flex items-center gap-2">
        <img
          className="w-14 h-14 rounded-12xl object-cover"
          loading="lazy"
          alt="Logo"
          src="/images-1@2x.png"
        />
        <Link to="/" className="text-background-white no-underline">
          <span className="tracking-[1px] leading-[34px] font-bold">
            Superteam SA Validator
          </span>
        </Link>
      </div>

      {/* Burger Menu Button */}
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="hidden mq750:flex flex-col justify-center items-center w-10 h-10 border-none bg-transparent cursor-pointer z-[100]"
        aria-label="Toggle menu"
      >
        <span className={`block w-6 h-0.5 bg-background-white transition-all duration-300 ${isMenuOpen ? 'rotate-45 translate-y-[6px]' : ''}`} />
        <span className={`block w-6 h-0.5 bg-background-white my-1.5 transition-all duration-300 ${isMenuOpen ? 'opacity-0' : ''}`} />
        <span className={`block w-6 h-0.5 bg-background-white transition-all duration-300 ${isMenuOpen ? '-rotate-45 -translate-y-[6px]' : ''}`} />
      </button>

      {/* Desktop Navigation */}
      <nav className="mq750:hidden flex gap-8">
        {navItems.map(renderNavLink)}
      </nav>

      {/* Mobile Menu */}
      <div className={`fixed top-0 right-0 h-screen w-64 bg-mediumblue shadow-lg transform transition-transform duration-300 ease-in-out pt-20 px-6 z-[100]
        ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <nav className="flex flex-col gap-6">
          {navItems.map((item) => 
            item.isExternal ? (
              <a
                key={item.path}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                className="text-lg font-bold no-underline text-background-white transition-colors hover:text-gray-300"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.title}
              </a>
            ) : (
              <Link
                key={item.path}
                to={item.path}
                className={`text-lg font-bold no-underline text-background-white transition-colors hover:text-gray-300
                  ${location.pathname === item.path ? 'border-b-2 border-background-white' : ''}`}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.title}
              </Link>
            )
          )}
        </nav>
      </div>

      {/* Overlay for mobile menu */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[90]"
          onClick={() => setIsMenuOpen(false)}
        />
      )}
    </header>
  );
};

export default Navbar;