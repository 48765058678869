import HeroHome from "../components/HeroHome";
import SectionAbout from "../components/SectionAbout";
import StakingWithUs from "../components/StakingWithUs";
import FrameComponent1 from "../components/FrameComponent1";
import TeamSection from "../components/TeamSection";
import StakeWithUs from "../components/StakeWithUs";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Homepage = () => {
  return (
    <div className="w-full min-h-screen flex flex-col bg-background-white overflow-x-hidden">
      <Navbar />
      <main className="flex-1 flex flex-col w-full max-w-[100vw] overflow-x-hidden">
        <div className="flex flex-col w-full items-center">
          <HeroHome />
          <SectionAbout />
          <StakingWithUs />
          <FrameComponent1 />
          <TeamSection />
          <StakeWithUs />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Homepage;
