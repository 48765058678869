import React from 'react';
import PropTypes from 'prop-types';

const TeamMember = ({ imageSrc, name, role, roleStyles = {} }) => (
  <div className="flex flex-col items-center gap-[14px]">
    <div className="relative w-[308px] h-[308px] overflow-hidden rounded-lg">
      <img 
        src={imageSrc} 
        alt={name}
        className="w-full h-full object-cover"
      />
    </div>
    <div className="flex flex-col items-center gap-2">
      <h3 className="text-[32px] leading-[32px] font-bold">{name}</h3>
      <p className="text-[20px] text-text-title-text" style={roleStyles}>{role}</p>
    </div>
  </div>
);

const TeamSection = ({ className = "" }) => {
  const teamMembers = [
    {
      imageSrc: "/whatsapp-image-20241115-at-203548-1@2x.png",
      name: "owen.sol",
      role: "Lead Developer"
    },
    {
      imageSrc: "/frog-tony-stark-1-1@2x.png",
      name: "Junebox",
      role: "Operations Lead",
      roleStyles: { minWidth: "100.5px" }
    }
  ];

  return (
    <section className={`self-stretch h-[836px] relative max-w-full text-left text-29xl text-text-title-text font-body-normal-bold mq1225:h-auto mq1225:min-h-[836] ${className}`}>
      <div className="flex-1 bg-background-white overflow-hidden flex flex-col items-start justify-start pt-32 px-[365px] pb-[119px] box-border gap-[57px] max-w-full text-left text-29xl text-text-title-text font-body-normal-bold 
        mq750:gap-7 mq750:pt-[23px] mq750:px-[182px] mq750:pb-[77px] 
        mq450:px-5">
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-[242px] 
          mq750:px-[121px] mq450:px-5">
          <h1 className="m-0 text-[48px] leading-[52.8px] text-black font-bold font-inherit 
            mq750:text-[32px] mq750:leading-[42px] 
            mq450:text-[32px] mq450:leading-[36px]">
            Our Team
          </h1>
        </div>
        
        <div className="self-stretch flex flex-row items-start justify-center flex-wrap gap-[54px] max-w-full text-center text-[32px] 
          mq750:gap-[27px] mq750:flex-col mq750:items-center">
          {teamMembers.map((member, index) => (
            <TeamMember
              key={index}
              imageSrc={member.imageSrc}
              name={member.name}
              role={member.role}
              roleStyles={member.roleStyles}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;

