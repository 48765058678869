import PropTypes from "prop-types";

const StakingWithUs = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-gray-200 overflow-hidden flex flex-row items-start justify-start pt-[93px] px-0 pb-[98px] box-border gap-[52px] max-w-full text-left text-29xl text-background-white font-body-normal-bold 
      mq750:gap-0 mq750:px-6 mq750:flex-col mq1050:pt-[60px] mq1050:pb-16 mq1050:box-border mq1225:flex-wrap mq450:pt-[39px] mq450:pb-[42px] mq450:box-border ${className}`}
    >
      <div className="h-[709px] flex flex-col items-start justify-start pt-[67px] px-0 pb-0 box-border min-w-[627px] max-w-full mq750:h-auto mq750:pt-[29px] mq750:min-w-full mq750:pb-0 mq1225:flex-1">
        <div className="self-stretch flex flex-col items-end justify-start gap-14 max-w-full shrink-0 mq750:gap-7">
          <div className="w-[462px] flex flex-col items-start justify-start gap-[17px] max-w-full">
            <div className="flex flex-col items-start justify-start pt-0 pb-3.5 pl-0 pr-5 box-border gap-[30px] max-w-full">
              <h1 className="m-0 relative text-inherit leading-[52.8px] font-bold font-[inherit] mq750:text-19xl mq750:leading-[42px] mq450:text-10xl mq450:leading-[32px]">
                Staking with us:
              </h1>
              <div className="w-[122px] h-px relative border-background-white border-t-[1px] border-solid box-border" />
            </div>
            <b className="w-[436px] relative text-lg leading-[32px] inline-block max-w-full">{`High performance, low fees. `}</b>
            <div className="self-stretch flex flex-row items-start justify-start gap-[17px] max-w-full text-5xl mq450:flex-wrap">
              <div className="flex flex-col items-start justify-start pt-[3px] px-0 pb-0">
                <div className="self-stretch flex flex-col items-start justify-start gap-[58px]">
                  <div className="self-stretch h-[52px] relative rounded-3xs bg-background-white">
                    <div className="absolute top-[0px] left-[0px] rounded-3xs bg-background-white w-full h-full hidden" />
                    <img
                      className="absolute top-[10px] left-[10px] w-8 h-8 overflow-hidden z-[1]"
                      loading="lazy"
                      alt=""
                      src="/trendingupoutline.svg"
                    />
                  </div>
                  <div className="flex flex-row items-start justify-start pt-0 px-0 pb-2">
                    <img
                      className="h-[52px] w-[52px] relative"
                      loading="lazy"
                      alt=""
                      src="/group-6.svg"
                    />
                  </div>
                  <div className="self-stretch h-[52px] relative rounded-3xs bg-background-white">
                    <div className="absolute top-[0px] left-[0px] rounded-3xs bg-background-white w-full h-full hidden" />
                    <img
                      className="absolute top-[8px] left-[8px] w-9 h-9 overflow-hidden z-[1]"
                      loading="lazy"
                      alt=""
                      src="/cash.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[33px] min-w-[255px] max-w-full mq450:gap-4">
                <div className="flex flex-row items-start justify-start py-0 px-[3px] box-border max-w-full">
                  <div className="flex flex-col items-start justify-start max-w-full">
                    <h3 className="m-0 relative text-inherit leading-[32px] font-bold font-[inherit] mq450:text-lgi mq450:leading-[26px]">
                      Low Commission
                    </h3>
                    <div className="relative text-base leading-[150%]">
                      <p className="m-0">
                        We keep our commissions as low as possible,
                      </p>
                      <p className="m-0">maximizing yields for our stakers</p>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <h3 className="m-0 self-stretch relative text-inherit leading-[32px] font-bold font-[inherit] mq450:text-lgi mq450:leading-[26px]">
                    Latest Hardware
                  </h3>
                  <div className="relative text-base leading-[150%] inline-block max-w-full mt-[-1px]">
                    <p className="m-0 whitespace-pre-wrap">
                      We work with industry leaders providing us with
                    </p>
                    <p className="m-0">{`the latest and best hardware on the market `}</p>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start py-0 pl-0 pr-[18px]">
                  <b className="relative leading-[32px] mq450:text-lgi mq450:leading-[26px]">
                    24/7 Operation
                  </b>
                  <div className="relative text-base leading-[150%] mt-[-1px]">
                    <p className="m-0">{`Our team consistently ensures we stay as close to `}</p>
                    <p className="m-0">{`100% up-time as possible. `}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[571px] flex flex-row items-start justify-end py-0 px-[109px] box-border max-w-full mq750:hidden">
            <div className="self-stretch w-[807px] relative [filter:blur(157.3px)] rounded-[50%] bg-darkblue-100 max-w-[229%] shrink-0" />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end justify-start gap-[17px] max-w-full mq750:min-w-full">
        <div className="w-[562px] flex flex-row items-end justify-start py-0 pl-5 pr-0 box-border gap-[25px] max-w-full mq750:flex-col mq750:items-center mq750:pl-0">
          <img
            className="h-[335px] w-[247px] relative object-cover mq750:w-[80%] mq750:max-w-[247px] mq750:mt-12 mq750:mb-8"
            loading="lazy"
            alt=""
            src="/0x1a7675@2x.png"
          />
          <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[3px] box-border min-w-[175px] mq750:hidden">
            <div className="self-stretch h-[307px] relative">
              <div className="absolute top-[0px] left-[44px] w-[181.8px] h-[202px] opacity-[0.5] mix-blend-normal">
                <div className="absolute top-[0px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[0px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[19.9px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[39.8px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[59.7px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[79.6px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[99.5px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[119.4px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[139.3px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[159.2px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[179.1px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[0px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[19.9px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[39.7px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[59.6px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[79.5px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[99.3px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[119.2px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[139.1px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[159px] bg-darkslategray w-[3px] h-[3px]" />
                <div className="absolute top-[199px] left-[178.8px] bg-darkslategray w-[3px] h-[3px]" />
                </div>
                <img
                className="absolute top-[36px] left-[0px] w-[270px] h-[271px] object-cover z-[1]"
                loading="lazy"
                alt=""
                src="/0x1a7664@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[21px] max-w-full mq750:hidden">
          <div className="h-[323px] relative min-w-[221px] mq750:flex-1">
            <div className="absolute top-[121px] left-[0px] w-[181.8px] h-[202px] opacity-[0.5] mix-blend-normal">
              <div className="absolute top-[0px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[0px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[19.9px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[39.8px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[59.7px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[79.6px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[99.5px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[119.4px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[139.3px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[159.2px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[179.1px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[0px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[19.9px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[39.7px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[59.6px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[79.5px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[99.3px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[119.2px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[139.1px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[159px] bg-background-white w-[3px] h-[3px]" />
              <div className="absolute top-[199px] left-[178.8px] bg-background-white w-[3px] h-[3px]" />
              </div>
            <img
              className="absolute top-[0px] left-[20px] w-[201px] h-[300px] object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/0x1a7706@2x.png"
            />
          </div>
          <img
            className="h-[357px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[231px]"
            loading="lazy"
            alt=""
            src="/0x1a7894@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

StakingWithUs.propTypes = {
  className: PropTypes.string,
};

export default StakingWithUs;