// components/Footer.jsx
const Footer = ({ className = "" }) => {
  return (
    <footer
      className={`w-full bg-mediumblue flex flex-row items-start justify-start py-20 px-[104px] box-border gap-[115px] text-base text-gainsboro-100 font-body-normal-bold
      mq1050:flex-wrap mq1050:gap-[57px] mq1050:px-[52px]
      mq750:gap-[29px] mq750:px-6 mq750:py-[52px] ${className}`}
    >
      {/* Logo and Description */}
      <div className="w-[230px] flex flex-col gap-[21px] mq1050:flex-1">
        <img
          className="w-14 h-14 object-cover"
          loading="lazy"
          alt="Logo"
          src="/logo@2x.png"
        />
        <p className="m-0 leading-[25.2px]">
          Superteam SA Validator, the second on the African continent.
        </p>
      </div>

      {/* Contact and Social Links */}
      <div className="flex flex-row justify-between gap-12 text-background-white flex-1 
        mq750:flex-col mq750:gap-8">
        {/* Contact Info */}
        <div className="flex flex-col gap-5">
          <b className="leading-[22.4px]">Get in Touch</b>
          <div className="flex flex-col gap-6">
            <a href="mailto:info@superteamvalidator.com" className="flex items-center gap-4 text-background-white no-underline">
              <img className="w-6 h-6" alt="Email" src="/mail.svg" />
              <span className="leading-[32px]">info@superteamvalidator.com</span>
            </a>
            <a href="tel:+27711055400" className="flex items-center gap-4 text-background-white no-underline">
              <img className="w-6 h-6" alt="Phone" src="/phone.svg" />
              <span className="leading-[32px]">+27711055400</span>
            </a>
          </div>
        </div>

        {/* Social Links */}
        <div className="flex flex-col gap-[19px]">
          <b className="leading-[22.4px]">Socials</b>
          <div className="flex gap-5">
            <a 
              href="https://twitter.com/SuperteamSA" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:opacity-80 transition-opacity"
            >
              <img className="w-6 h-6" alt="Twitter" src="/twitter.svg" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;