import PropTypes from "prop-types";

const StakeWithUs = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch h-[798px] relative bg-gray-300 overflow-hidden shrink-0 text-left text-9xl text-background-white font-body-normal-bold ${className}`}
    >
      <div className="absolute top-[-180px] left-[0px] w-[1440px] h-[1402px]">
        <div className="absolute top-[469px] left-[960px] [filter:blur(157.3px)] rounded-[50%] bg-darkblue-100 w-[807px] h-[784px]" />
        <div className="absolute top-[0px] left-[-350px] [filter:blur(157.3px)] rounded-[50%] bg-darkblue-100 w-[807px] h-[784px]" />
        <div className="absolute top-[719px] left-[-815px] [filter:blur(157.3px)] rounded-[50%] bg-darkblue-200 w-[2480px] h-[1110px] z-[1]" />
      </div>

      <a 
        href="https://stakewiz.com/validator/stsaYQJUhKZDHSqndGtgo6jgbhVaHBSHhtfVWxCwrhD"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer [border:none] pt-4 px-[92px] pb-[17px] bg-orange-100 absolute top-[494px] left-[140px] rounded-lg flex flex-row items-start justify-start z-[2] hover:bg-chocolate mq750:left-6 no-underline text-decoration-none"
      >
        <b className="h-[37px] relative text-11xl leading-[19.6px] flex font-body-normal-bold text-gray-300 text-left items-center mq750:text-5xl mq750:leading-[16px] mq450:text-lg mq450:leading-[12px] no-underline">
          StakeWiz
        </b>
      </a>

      <h2 className="m-0 absolute top-[446px] left-[140px] text-inherit leading-[32px] font-bold font-inherit inline-block w-[359px] h-8 z-[2] mq750:left-6 mq450:text-3xl mq450:leading-[26px]">
        Stake via StakeWiz below:
      </h2>

      <div className="absolute top-[128px] left-[140px] mq750:left-6">
        <h1 className="m-0 text-29xl leading-[64px] font-bold font-inherit text-gray-100 flex items-center w-[472px] z-[2] mq750:text-19xl mq750:leading-[51px] mq450:text-10xl mq450:leading-[38px]">
          <span className="w-full">
            <p className="[margin-block-start:0] [margin-block-end:24px]">Stake with us</p>
            <p className="[margin-block-start:0] [margin-block-end:24px]">Help bring Solana</p>
            <div className="relative">
              {/* Yellow box behind text */}
              <img
                className="absolute top-1/2 left-0 -translate-y-1/2 w-[212px] h-[58px] z-[1]
                  mq750:w-[180px] mq450:w-[150px]"
                loading="lazy"
                alt=""
                src="/rectangle-6-1.svg"
              />
              {/* Text over yellow box */}
              <p className="m-0 relative z-[2]">to Africa</p>
            </div>
          </span>
        </h1>
      </div>
    </section>
  );
};

StakeWithUs.propTypes = {
  className: PropTypes.string,
};

export default StakeWithUs;