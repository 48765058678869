import PropTypes from "prop-types";

const MetricsSection = ({ className = "" }) => {
  return (
    <div
      className={`h-[545px] flex-1 bg-background-white overflow-hidden flex flex-row items-start justify-start pt-[124px] px-10 pb-[195px] box-border gap-[116px] max-w-full text-left text-29xl text-gray-300 font-body-normal-bold mq750:h-auto mq750:flex-col mq750:items-center mq750:pt-16 mq750:px-6 mq750:pb-16 mq750:gap-12 mq450:pt-10 mq450:px-4 mq450:pb-12 ${className}`}
    >
      <div className="h-[491px] w-[794px] relative max-w-[calc(100%_-_513px)] mq750:max-w-[90%] mq1225:hidden">
        <div className="absolute top-[0px] left-[0px] bg-mediumblue w-[73px] h-[116px]" />
        <div className="absolute top-[0px] left-[0px] w-[770px] h-[357px] opacity-[0.5] mix-blend-normal">
          <div className="grid grid-cols-10 gap-[9.8px] absolute top-[257px] left-[680px]">
            {[...Array(100)].map((_, index) => (
              <div key={index} className="bg-darkslategray w-[1.5px] h-[1.5px]" />
            ))}
          </div>
        </div>
        <img
          className="absolute top-[10px] left-[2px] w-[792px] h-[481px] object-cover z-[2]"
          loading="lazy"
          alt=""
          src="/0x1a7868@2x.png"
        />
      </div>

      <div className="w-[497px] flex flex-col items-start justify-start pt-7 px-0 pb-0 box-border max-w-[calc(100%_-_810px)] mq1225:max-w-full mq750:w-full mq750:items-center">
        <div className="self-stretch flex flex-col items-start justify-start gap-[25px] max-w-full mq750:items-center">
          <h1 className="m-0 relative text-[48px] leading-[52.8px] font-bold font-inherit inline-block max-w-full 
            mq750:text-[40px] mq750:leading-[44px] 
            mq450:text-[32px] mq450:leading-[36px]">
            Current Metrics
          </h1>
          <div className="w-[122px] h-px relative border-gainsboro-200 border-t-[1px] border-solid" />
          <div className="self-stretch flex flex-col items-start justify-start gap-[7px] max-w-full font-headings-bold-48 mq750:items-center">
            <div className="self-stretch flex flex-row items-start justify-start gap-[45px] mq750:flex-col mq750:items-center mq750:gap-6">
              <div className="flex flex-col items-start justify-start mq750:items-center">
                <b className="relative leading-[64px] whitespace-nowrap mq750:text-[40px] mq450:text-[32px]">
                  10,000
                </b>
                <b className="relative text-base leading-[150%] font-paragraph-bold-16 mt-[7px]">
                  Staked Solana
                </b>
              </div>
              <div className="flex flex-col items-start justify-start mq750:items-center">
                <b className="relative leading-[64px] whitespace-nowrap mq750:text-[40px] mq450:text-[32px]">
                  8%
                </b>
                <b className="relative text-base leading-[150%] font-paragraph-bold-16 mt-[7px]">
                  Avg APY
                </b>
              </div>
              <div className="flex flex-col items-start justify-start mq750:items-center">
                <b className="relative leading-[64px] whitespace-nowrap mq750:text-[40px] mq450:text-[32px]">
                  3
                </b>
                <b className="relative text-base leading-[150%] font-paragraph-bold-16 mt-[7px]">
                  Active Stakers
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MetricsSection.propTypes = {
  className: PropTypes.string,
};

export default MetricsSection;
