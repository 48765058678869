import HeroHomeInfo from "./HeroHomeInfo";
import PropTypes from "prop-types";

const HeroHome = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch relative min-h-screen w-full max-w-full text-left text-29xl text-gray-100 font-body-normal-bold overflow-hidden bg-gray-300 ${className}`}
    >
      {/* Background Effects */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Main Blur Effects */}
        <div className="absolute top-[0px] left-[960px] [filter:blur(157.3px)] rounded-[50%] bg-darkblue-100 w-[807px] h-[784px]" />
        <div className="absolute top-[314px] left-[-520px] [filter:blur(157.3px)] rounded-[50%] bg-darkblue-200 w-[2480px] h-[1110px]" />
      </div>

      {/* Content */}
      <div className="relative w-full flex flex-col items-start justify-start max-w-full z-10">
        <div className="w-full h-full absolute !m-[0] inset-0 rounded-t-none rounded-br-none rounded-bl-61xl bg-gray-500" />
        <HeroHomeInfo />
      </div>
    </section>
  );
};

HeroHome.propTypes = {
  className: PropTypes.string,
};

export default HeroHome;