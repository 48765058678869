import MetricsSection from "./MetricsSection";
import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start py-0 pl-0.5 pr-0 box-border max-w-full text-left text-29xl text-gray-300 font-body-normal-bold ${className}`}
    >
      <MetricsSection />
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
