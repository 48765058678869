import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroContactPage from "../components/HeroContactPage";

const ContactUs = () => {
  return (
    <div className="w-full min-h-screen flex flex-col bg-background-white overflow-x-hidden">
      <Navbar />
      <main className="flex-1 flex flex-col w-full max-w-[100vw] overflow-x-hidden">
        <div className="flex flex-col w-full items-center">
          <HeroContactPage />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactUs;